import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {isMobile} from 'react-device-detect'
import {TextSub} from '../components/TextSub'
import ImagePath from '../../commons/ImagePath'
import {toast} from 'react-toastify'

export function PlaceDetail() {
  const {place_id} = useParams<{place_id: string}>()

  const [place, setPlace] = useState<any>(null)

  useEffect(() => {
    getPlace()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getPlace = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/${place_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setPlace(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getPlaceAlgoliaUpdate = () => {
    if (window.confirm('알고리아 정보 갱신 하시겠습니까?')) {
      axios
        .get(`${process.env.REACT_APP_API2_URL}am/place/${place_id}/algolia/update`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            toast('success')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const updateHourplaceOnly = (only = 0) => {
    let text = '아워플레이스 Only 장소로 지정 하시겠습니까?'
    if (only === 0) {
      text = '아워플레이스 Only 장소를 제외 시키겠습니까?'
      place.hourplace_only_main_show = 0
    }

    place.hourplace_only = only

    if (window.confirm(text)) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/place/only/${place_id}`, place)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            alert('success')
            window.location.reload()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const updateHourplaceOnlyMainShow = (show = 0) => {
    let text = '아워플레이스 Only 장소를 메인 노출 처리 하시겠습니까?'
    if (show === 0) {
      text = '아워플레이스 Only 장소를 메인 노출 숨김 처리 하시겠습니까?'
    }

    place.hourplace_only_main_show = show

    if (window.confirm(text)) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/place/only/${place_id}`, place)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            alert('success')
            window.location.reload()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>장소 상세</PageTitle>

      <div
        style={{
          padding: isMobile ? 0 : 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
          <div style={{margin: 4}}>
            <label
              style={{marginLeft: 10}}
              onClick={() => {
                window.open(`${process.env.REACT_APP_WEB_URL}place/${place_id}`)
              }}
              className='btn btn-sm btn-primary'
            >
              웹에서 보기
            </label>
          </div>
          <div style={{margin: 4}}>
            <label
              style={{marginLeft: 10}}
              onClick={() => {
                window.location.href = `/place/${place_id}/edit`
              }}
              className='btn btn-sm btn-info'
            >
              수정하기
            </label>
          </div>
          <div style={{margin: 4}}>
            <label
              style={{marginLeft: 10}}
              onClick={() => {
                window.location.href = `/place/${place_id}/price/edit`
              }}
              className='btn btn-sm btn-info'
            >
              가격 수정하기
            </label>
          </div>
          <div style={{margin: 4}}>
            <label
              style={{marginLeft: 10}}
              onClick={() => {
                getPlaceAlgoliaUpdate()
              }}
              className='btn btn-sm btn-danger'
            >
              검색 정보 갱신
            </label>
          </div>

          <div style={{margin: 4}}>
            <label
              style={{marginLeft: 10}}
              onClick={() => {
                place.hourplace_only === 1 ? updateHourplaceOnly(0) : updateHourplaceOnly(1)
              }}
              className='btn btn-sm btn-warning'
            >
              아워플레이스 Only
            </label>
          </div>

          {place !== null && place.hourplace_only === 1 && (
            <>
              <div style={{margin: 4}}>
                <label
                  style={{marginLeft: 10}}
                  onClick={() => {
                    place.hourplace_only_main_show === 1 ? updateHourplaceOnlyMainShow(0) : updateHourplaceOnlyMainShow(1)
                  }}
                  className='btn btn-sm btn-warning'
                >
                  아워플레이스 Only
                  <br />
                  메인 노출 여부
                </label>
              </div>
            </>
          )}
        </div>

        {place !== null && (
          <div style={{width: '100%', marginTop: 20}}>
            <h1>
              {place.id}. {place.title}
            </h1>
          </div>
        )}

        {place !== null && (
          <div style={{padding: isMobile ? 0 : 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <TextSub title={'아워플레이스 Only'} subTitle={place.hourplace_only === 1 ? 'Only' : '-'} />
            <div className='separator my-10' />
            {place.hourplace_only === 1 && (
              <>
                <TextSub title={'아워플레이스 Only\n메인 노출'} subTitle={place.hourplace_only_main_show === 1 ? '노출' : '미노출'} />
                <div className='separator my-10' />
              </>
            )}
            <TextSub
              title={'상태'}
              subTitle={
                place.status === 'publish'
                  ? '게시 중'
                  : place.status === 'hide' && place.inactive === 0
                  ? '숨김'
                  : place.status === 'hide' && place.inactive === 1
                  ? '미활동 숨김'
                  : place.status === 'unpublish'
                  ? '관리자 숨김'
                  : place.status === 'leave'
                  ? '탈퇴'
                  : place.status === 'temporary'
                  ? '작성중'
                  : place.status === 'trash'
                  ? '삭제'
                  : place.status
              }
            />
            <div className='separator my-10' />
            <TextSub title={'제목'} subTitle={place.title} />
            <div className='separator my-10' />
            <TextSub title={'요금제 타입'} subTitle={place.price_type === 1 ? '차등' : '단일'} />
            <div className='separator my-10' />
            {place.price_type === 0 ? (
              <TextSub title={'금액'} subTitle={place.price_guest_vat.toLocaleString('en')} />
            ) : (
              <>
                <TextSub title={'평일'} subTitle={place.place_price[1][0].toLocaleString('en')} />
                <TextSub title={'주말'} subTitle={place.place_price[0][0].toLocaleString('en')} />
              </>
            )}

            <div className='separator my-10' />
            <TextSub title={'인당 추가 요금'} subTitle={place.price_extra.toLocaleString('en')} />
            <div className='separator my-10' />
            <TextSub title={'결제 방식'} subTitle={place.payments_type === 0 ? '승인 결제' : '바로 결제'} />
            <div className='separator my-10' />
            <TextSub title={'카테고리'} subTitle={`${place.category_main} / ${place.category_sub}`} />
            <div className='separator my-10' />
            <TextSub title={'지역'} subTitle={place.local} />
            <div className='separator my-10' />
            <TextSub title={'태그'} subTitle={place.tag} />
            <div className='separator my-10' />
            <TextSub title={'검색용 태그'} subTitle={place.search_tag} />
            <div className='separator my-10' />
            <TextSub title={'장소 사진'} subTitle={''} />
            <div style={{display: 'flex', flexDirection: 'column'}}>
              {place.place_image_path_arr.map((image: any, index: number) => {
                return (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div>
                      <div
                        style={{
                          position: 'relative',
                          margin: 4,
                          padding: 4,
                          width: 200,
                          height: 200,
                          overflow: 'hidden',
                          border: 'solid 1px #CCC',
                        }}
                      >
                        <img
                          src={ImagePath.get(image, '100x100')}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                          alt={ImagePath.get(image, '100x100')}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          position: 'relative',
                          margin: 4,
                          padding: 4,
                          width: 200,
                          height: 200,
                          overflow: 'hidden',
                          border: 'solid 1px #CCC',
                        }}
                      >
                        <img
                          src={ImagePath.get(image, '2000x2000', 'inside')}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                          alt={ImagePath.get(image, '2000x2000', 'inside')}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          position: 'relative',
                          margin: 4,
                          padding: 4,
                          width: 200,
                          height: 200,
                          overflow: 'hidden',
                          border: 'solid 1px #CCC',
                        }}
                      >
                        <img
                          src={ImagePath.get(image, '550x364')}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                          alt={ImagePath.get(image, '550x364')}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          position: 'relative',
                          margin: 4,
                          padding: 4,
                          width: 200,
                          height: 200,
                          overflow: 'hidden',
                          border: 'solid 1px #CCC',
                        }}
                      >
                        <img
                          src={ImagePath.get(image, '500x500')}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                          alt={ImagePath.get(image, '500x500')}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='separator my-10' />
            <TextSub title={'면적'} subTitle={place.area} />
            <div className='separator my-10' />
            <TextSub title={'층'} subTitle={place.floor} />
            <div className='separator my-10' />
            <TextSub title={'기본 인원'} subTitle={place.people} />
            <div className='separator my-10' />
            <TextSub title={'주차'} subTitle={place.parking} />
            <div className='separator my-10' />

            <TextSub title={'장소 소개'} subTitle={place.description} />
            <div className='separator my-10' />
            <TextSub title={'장소 이용 규칙'} subTitle={place.use_rules} />
            <div className='separator my-10' />
            <TextSub title={'주변 정보'} subTitle={place.nearby_information} />

            {place.safety_number !== null && place.safety_number !== undefined && (
              <>
                <div className='separator my-10' />
                <TextSub title={'050 번호'} subTitle={`${place.safety_number}(${place.safety_number_phone})`} />
                <TextSub title={'문의 가능 시간'} subTitle={`${place.safety_number_start} ~ ${place.safety_number_end}`} />
              </>
            )}

            <div className='separator my-10' />
            <TextSub title={'자동 전송 메시지'} subTitle={place.auto_send_info_message} />
            <div className='separator my-10' />
            <TextSub title={'주소'} subTitle={place.address} />
            <div className='separator my-10' />
            <TextSub title={'주소'} subTitle={place.address_old} />
          </div>
        )}
      </div>
    </>
  )
}
