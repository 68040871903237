import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import {TextSub} from '../components/TextSub'
import {isMobile} from 'react-device-detect'
import {toast} from 'react-toastify'
import SwitchButton from '../../components/SwitchButton'
import Modal from '../components/Modal'

export function UserEdit() {
  const {user_id} = useParams<{user_id: string}>()
  const [user, setUser] = useState<any>(null)
  const [modalLeaveVisible, setModalLeaveVisible] = useState(false)
  const [leaveReason, setLeaveReason] = useState('')

  useEffect(() => {
    getUser()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getUser = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/user/${user_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          setUser(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const update = () => {
    //console.log(user)
    console.log({
      name: user.name,
      comment: user.comment,
      company: user.company,
      host_name: user.host_name,
      host_comment: user.host_comment,
      group_id: user.group_id,
      guest_instagram_url: user.guest_career.socialMedia.instagram_url,
      guest_facebook_url: user.guest_career.socialMedia.facebook_url,
      guest_youtube_url: user.guest_career.socialMedia.youtube_url,
      guest_tiktok_url: user.guest_career.socialMedia.tiktok_url,
      guest_twitter_url: user.guest_career.socialMedia.twitter_url,
      guest_naver_blog_url: user.guest_career.socialMedia.naver_blog_url,
      host_instagram_url: user.host_career.socialMedia.instagram_url,
      host_facebook_url: user.host_career.socialMedia.facebook_url,
      host_youtube_url: user.host_career.socialMedia.youtube_url,
      host_tiktok_url: user.host_career.socialMedia.tiktok_url,
      host_twitter_url: user.host_career.socialMedia.twitter_url,
      host_naver_blog_url: user.host_career.socialMedia.naver_blog_url,
    })
    if (window.confirm('사용자 정보를 수정 하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/user/${user_id}`, {
          name: user.name,
          comment: user.comment,
          company: user.company,
          host_name: user.host_name,
          host_comment: user.host_comment,
          group_id: user.group_id,
          guest_instagram_url: user.guest_career.socialMedia.instagram_url,
          guest_facebook_url: user.guest_career.socialMedia.facebook_url,
          guest_youtube_url: user.guest_career.socialMedia.youtube_url,
          guest_tiktok_url: user.guest_career.socialMedia.tiktok_url,
          guest_twitter_url: user.guest_career.socialMedia.twitter_url,
          guest_naver_blog_url: user.guest_career.socialMedia.naver_blog_url,
          host_instagram_url: user.host_career.socialMedia.instagram_url,
          host_facebook_url: user.host_career.socialMedia.facebook_url,
          host_youtube_url: user.host_career.socialMedia.youtube_url,
          host_tiktok_url: user.host_career.socialMedia.tiktok_url,
          host_twitter_url: user.host_career.socialMedia.twitter_url,
          host_naver_blog_url: user.host_career.socialMedia.naver_blog_url,
        })
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            window.location.href = `/user/${user_id}`
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const leave = () => {
    console.log(leaveReason)
    console.log(user.id)
    console.log(user_id)

    if (window.confirm('탈퇴 처리 하시겠습니까?')) {
      axios
        .patch(`${process.env.REACT_APP_API2_URL}am/user/${user_id}/leave`, {
          userId: user_id,
          leaveReason: leaveReason
        })
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            window.location.href = `/user/${user_id}`
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>사용자 수정</PageTitle>

      <div
        style={{
          padding: isMobile ? 0 : 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {user !== null && (
          <>
            <div>
              <h1>
                {user.id}. {user.real_name}
              </h1>
            </div>
            {user.leave === 0 && (
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
              <div style={{margin: 4}}>
                <label
                  style={{marginLeft: 10}}
                  onClick={() => {
                    setModalLeaveVisible(true)
                  }}
                  className='btn btn-sm btn-danger'
                >
                  사용자 탈퇴
                </label>
              </div>
            </div>
            )}
          </>
        )}
        {user !== null && (
          <div style={{padding: isMobile ? 0 : 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <TextSub title={'게스트 프로필 이미지'} subTitle={''} />
            <div style={{width: 150, height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
              <img src={user.image_url} alt='' style={{width: 100, height: 100, objectFit: 'cover', borderRadius: 100}} />
              <div
                style={{
                  position: 'absolute',
                  width: 30,
                  height: 30,
                  borderTopLeftRadius: 8,
                  bottom: 0,
                  right: 0,
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h4 style={{margin: 0}}>{user.grade}</h4>
              </div>
            </div>
            <TextSub title={'호스트 프로필 이미지'} subTitle={''} />
            <div style={{width: 150, height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
              <img src={user.host_image_url} alt='' style={{width: 100, height: 100, objectFit: 'cover', borderRadius: 100}} />
              <div
                style={{
                  position: 'absolute',
                  width: 30,
                  height: 30,
                  borderTopLeftRadius: 8,
                  bottom: 0,
                  right: 0,
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h4 style={{margin: 0}}>{user.grade}</h4>
              </div>
            </div>
            {user.leave === 1 && <p style={{color: 'red'}}>탈퇴 [{user.leave_comment}]</p>}
            {user.black === 1 && <p style={{color: 'red'}}>블랙 [{user.black_comment}]</p>}
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div>
                <TextSub title={'닉네임 (이름)'} subTitle={`${user.name} (${user.real_name})`} marginTop={0} />
              </div>
            </div>
            <TextSub title={'이메일'} subTitle={user.email} marginTop={-10} />
            <TextSub title={'생년월일'} subTitle={user.birth} marginTop={-10} />
            <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(user.birth))).asYears())}세`} marginTop={-10} />
            <TextSub title={'전화번호'} subTitle={user.phone} marginTop={-10} />
            <TextSub title={'성별'} subTitle={user.gender === 1 ? '남' : '여'} marginTop={-10} />
            <TextSub title={'국적'} subTitle={user.national === 1 ? '외국인' : '내국인'} marginTop={-10} />
            {user.account !== undefined && user.account !== null && (
              <>
                <TextSub title={'정산 유형'} subTitle={user.account.type === 'individual' ? '개인' : '사업자'} marginTop={-4} />
                <TextSub title={'정산'} subTitle={`${user.account.bank_name} | ${user.account.bank_account} | ${user.account.bank_owner}`} marginTop={-4} />
              </>
            )}
            <div className='separator my-10' />
            <h1>기본정보</h1>
            <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start'}}>
              <p style={{fontWeight: 500, fontSize: 16, color: '#000000', whiteSpace: 'pre-wrap'}}>게스트 닉네임</p>
            </div>
            <div>
              <input
                type='text'
                className='form-control'
                placeholder=''
                value={user.name}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.name = e.target.value
                  setUser(temp)
                }}
              />
            </div>
            <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start'}}>
              <p style={{fontWeight: 500, fontSize: 16, color: '#000000', whiteSpace: 'pre-wrap'}}>게스트 소개</p>
              <p style={{marginLeft: 6, fontWeight: 500, fontSize: 16, color: '#979797', whiteSpace: 'pre-wrap'}}>최대 150자</p>
            </div>
            <div>
              <textarea
                className='form-control'
                style={{width: '100%', height: 50}}
                value={user.comment}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.comment = e.target.value
                  setUser(temp)
                }}
              />
            </div>
            {(user.permission === 1 || user.permission === 3) && (
              <>
                <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start'}}>
                  <p style={{minWidth: 80, fontWeight: 500, fontSize: 16, color: '#000000', whiteSpace: 'pre-wrap'}}>호스트 닉네임</p>
                </div>
                <div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    value={user.host_name}
                    onChange={(e) => {
                      const temp = Object.assign({}, user)
                      temp.host_name = e.target.value
                      setUser(temp)
                    }}
                  />
                </div>
                <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start'}}>
                  <p style={{fontWeight: 500, fontSize: 16, color: '#000000', whiteSpace: 'pre-wrap'}}>호스트 소개</p>
                  <p style={{marginLeft: 6, fontWeight: 500, fontSize: 16, color: '#979797', whiteSpace: 'pre-wrap'}}>최대 150자</p>
                </div>
                <div>
                  <textarea
                    className='form-control'
                    style={{width: '100%', height: 50}}
                    value={user.host_comment}
                    onChange={(e) => {
                      const temp = Object.assign({}, user)
                      temp.host_comment = e.target.value
                      setUser(temp)
                    }}
                  />
                </div>
              </>
            )}
            <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start'}}>
              <p style={{fontWeight: 500, fontSize: 16, color: '#000000', whiteSpace: 'pre-wrap'}}>추가정보</p>
              <p style={{marginLeft: 6, fontWeight: 500, fontSize: 16, color: '#979797', whiteSpace: 'pre-wrap'}}>사용하지 않는 항목입니다</p>
            </div>
            <div>
              <input
                type='text'
                className='form-control'
                placeholder=''
                value={user.company}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.company = e.target.value
                  setUser(temp)
                }}
              />
            </div>
            {user.leave === 1 && (
              <>
                <div className='separator my-10' />
                <TextSub title={'탈퇴 사유'} subTitle={''} />
                <div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    value={user.leave_comment}
                    onChange={(e) => {
                      const temp = Object.assign({}, user)
                      temp.leave_comment = e.target.value
                      setUser(temp)
                    }}
                  />
                </div>
              </>
            )}
            <TextSub title={'그룹 (게스트 그룹 지정)'} subTitle={''} />
            <div>
              <select
                className='form-select form-select-solid'
                name='type'
                value={user.group_id}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.group_id = e.target.value
                  setUser(temp)
                }}
              >
                <option value='0'>없음</option>
                {user.groups.map((group: any) => {
                  return <option value={group.id}>{group.name}</option>
                })}
              </select>
            </div>
            <div className='separator my-10' />
            <h1>커리어 정보</h1>
            <span style={{color: 'gray'}}>커리어 정보는 사용자 계정으로 switch 하여 수정해주세요.</span>
            <br />
            <div style={{marginTop: 20}}>
              <SwitchButton id={user.id}>Switch</SwitchButton>
            </div>
            <br />
            <br />

            {user.guest_career !== null && (
              <>
                <h5>게스트 직업</h5>
                {null !== user.guest_career.job && undefined !== user.guest_career.job && (
                  <>
                    <span style={{color: '#898A8D'}}>{user.guest_career.job}</span>
                    <br />
                  </>
                )}
                <br />
                <h5>게스트 경험</h5>
                {null !== user.guest_career.experience &&
                  undefined !== user.guest_career.experience &&
                  user.guest_career.experience.map((subItem: any, index: number) => {
                    return (
                      <>
                        <span style={{color: '#898A8D'}}>{subItem.company}</span>
                        {null !== subItem.company && <br />}
                        <span style={{color: '#898A8D'}}>{subItem.position}</span>
                        {null !== subItem.position && <br />}
                        {'' !== subItem.start && '' !== subItem.end && (
                          <>
                            <span style={{color: '#898A8D'}}>
                              {subItem.start} ~ {subItem.end}
                            </span>
                            <br />
                          </>
                        )}
                        <span style={{color: '#898A8D'}}>{subItem.content}</span>
                        {null !== subItem.content && <br />}
                      </>
                    )
                  })}
                <br />
                <h5>게스트 스킬 및 툴</h5>
                {null !== user.guest_career.skill && undefined !== user.guest_career.skill && (
                  <>
                    <span style={{color: '#898A8D'}}>{user.guest_career.skill}</span>
                    <br />
                  </>
                )}
                <br />
                <h5>게스트 수상 및 활동</h5>
                {null !== user.guest_career.activities &&
                  undefined !== user.guest_career.activities &&
                  user.guest_career.activities.map((subItem: any, index: number) => {
                    return (
                      <>
                        <span style={{color: '#898A8D'}}>{subItem.name}</span>
                        {null !== subItem.name && <br />}
                        <span style={{color: '#898A8D'}}>{subItem.date}</span>
                        {null !== subItem.date && <br />}
                        <br />
                      </>
                    )
                  })}
                <br />
                <h5>게스트 수료 및 자격증</h5>
                {null !== user.guest_career.certificate &&
                  undefined !== user.guest_career.certificate &&
                  user.guest_career.certificate.map((subItem: any, index: number) => {
                    return (
                      <>
                        <span style={{color: '#898A8D'}}>{subItem.name}</span>
                        {null !== subItem.name && <br />}
                        <span style={{color: '#898A8D'}}>{subItem.agency}</span>
                        {null !== subItem.agency && <br />}
                        <br />
                      </>
                    )
                  })}
              </>
            )}
            <br />
            <br />
            {user.host_career !== null && (
              <>
                <h5>호스트 직업</h5>
                {null !== user.host_career.job && undefined !== user.host_career.job && (
                  <>
                    <span style={{color: '#898A8D'}}>{user.host_career.job}</span>
                    <br />
                  </>
                )}
                <br />
                <h5>호스트 경험</h5>
                {null !== user.host_career.experience &&
                  undefined !== user.host_career.experience &&
                  user.host_career.experience.map((subItem: any, index: number) => {
                    return (
                      <>
                        <span style={{color: '#898A8D'}}>{subItem.company}</span>
                        {null !== subItem.company && <br />}
                        <span style={{color: '#898A8D'}}>{subItem.position}</span>
                        {null !== subItem.position && <br />}
                        {'' !== subItem.start && '' !== subItem.end && (
                          <>
                            <span style={{color: '#898A8D'}}>
                              {subItem.start} ~ {subItem.end}
                            </span>
                            <br />
                          </>
                        )}
                        <span style={{color: '#898A8D'}}>{subItem.content}</span>
                        {null !== subItem.content && <br />}
                      </>
                    )
                  })}
                <br />
                <h5>호스트 스킬 및 툴</h5>
                {null !== user.host_career.skill && undefined !== user.host_career.skill && (
                  <>
                    <span style={{color: '#898A8D'}}>{user.host_career.skill}</span>
                    <br />
                  </>
                )}
                <br />
                <h5>호스트 수상 및 활동</h5>
                {null !== user.host_career.activities &&
                  undefined !== user.host_career.activities &&
                  user.host_career.activities.map((subItem: any, index: number) => {
                    return (
                      <>
                        <span style={{color: '#898A8D'}}>{subItem.name}</span>
                        {null !== subItem.name && <br />}
                        <span style={{color: '#898A8D'}}>{subItem.date}</span>
                        {null !== subItem.date && <br />}
                        <br />
                      </>
                    )
                  })}
                <br />
                <h5>호스트 수료 및 자격증</h5>
                {null !== user.host_career.certificate &&
                  undefined !== user.host_career.certificate &&
                  user.host_career.certificate.map((subItem: any, index: number) => {
                    return (
                      <>
                        <span style={{color: '#898A8D'}}>{subItem.name}</span>
                        {null !== subItem.name && <br />}
                        <span style={{color: '#898A8D'}}>{subItem.agency}</span>
                        {null !== subItem.agency && <br />}
                        <br />
                      </>
                    )
                  })}
                <br />
              </>
            )}

            <div className='separator my-10' />
            <h1>소셜미디어</h1>
            <TextSub title={'게스트 인스타그램'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.guest_career.socialMedia.instagram_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.guest_career.socialMedia.instagram_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'게스트 페이스북'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.guest_career.socialMedia.facebook_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.guest_career.socialMedia.facebook_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'게스트 유튜브'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.guest_career.socialMedia.youtube_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.guest_career.socialMedia.youtube_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'게스트 틱톡'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.guest_career.socialMedia.tiktok_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.guest_career.socialMedia.tiktok_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'게스트 트위터'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.guest_career.socialMedia.twitter_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.guest_career.socialMedia.twitter_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'게스트 네이버 블로그'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.guest_career.socialMedia.naver_blog_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.guest_career.socialMedia.naver_blog_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'호스트 인스타그램'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.host_career.socialMedia.instagram_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.host_career.socialMedia.instagram_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'호스트 페이스북'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.host_career.socialMedia.facebook_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.host_career.socialMedia.facebook_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'호스트 유튜브'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.host_career.socialMedia.youtube_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.host_career.socialMedia.youtube_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'호스트 틱톡'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.host_career.socialMedia.tiktok_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.host_career.socialMedia.tiktok_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'호스트 트위터'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.host_career.socialMedia.twitter_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.host_career.socialMedia.twitter_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <TextSub title={'호스트 네이버 블로그'} subTitle={''} />
            <div>
              <textarea
                style={{width: '100%', height: 50}}
                value={user.host_career.socialMedia.naver_blog_url}
                onChange={(e) => {
                  const temp = Object.assign({}, user)
                  temp.host_career.socialMedia.naver_blog_url = e.target.value
                  setUser(temp)
                }}
              />
            </div>

            <div style={{width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{padding: 10}}>
                <label
                  onClick={() => {
                    update()
                  }}
                  className='btn btn-primary'
                >
                  수정하기
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      {modalLeaveVisible && (
        <Modal
          visible={modalLeaveVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalLeaveVisible(false)
          }}
          className={''}
        >
          <div>
            <div style={{marginTop: 26}}>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <textarea
                  rows={10}
                  cols={90}
                  defaultValue={leaveReason}
                  onChange={(e) => {
                    setLeaveReason(e.target.value)
                  }}
                ></textarea>
              </div>

              <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className={'row'}>
                  <div style={{padding: 4, width: 'fit-content'}}>
                    <label
                      onClick={() => {
                        setModalLeaveVisible(false)
                        leave()
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      등록
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

    </>
  )
}
