import React, {useEffect, useState} from 'react'

import {initializeApp} from 'firebase/app'
import {getDatabase, ref, onValue} from 'firebase/database'

export function NorthStarMetric() {

  const firebaseConfig = JSON.parse(`${process.env.REACT_APP_FIREBASE_CONFIG}`)

  const [northstarmetric, setNorthStarMetric] = useState<any>(null)
  const [updateTime, setUpdateTime] = useState('')
  const app = initializeApp(firebaseConfig)
  const database = getDatabase(app)
  const starCountRef = ref(database, 'admin/northstarmetric')
  const lastYear = new Date().getFullYear() - 1;
  const lastMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const formattedLastYear = `${lastYear.toString().slice(2)}년`;
  const formattedLastYearMonth = `${lastYear.toString().slice(2)}년 ${lastMonth}월`;
  const formattedCurrentYear = `${currentYear.toString().slice(2)}년`;
  const formattedCurrentYearMonth = `${currentYear.toString().slice(2)}년 ${currentMonth}월`;

  useEffect(() => {
    return onValue(starCountRef, (snapshot) => {
      const data = snapshot.val()
      console.log(data)
      setNorthStarMetric(data)
      setUpdateTime(data.updated_at)

      console.log('northstarmetric : ' + northstarmetric[lastYear].t1)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1); // 어제 날짜로 설정
    return yesterday.toISOString().split('T')[0]; // "YYYY-MM-DD" 형식
  };

  const getDayBeforeYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 2); // 어제 날짜로 설정
    return yesterday.toISOString().split('T')[0]; // "YYYY-MM-DD" 형식
  };

  const getYesterdayYYYYMMDD = () => {
    const dayBeforeYesterday = new Date();
    dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 1); // 전 날짜 설정

    // YYYYMMDD 형식으로 변환
    const year = dayBeforeYesterday.getFullYear();
    const month = String(dayBeforeYesterday.getMonth() + 1).padStart(2, "0");
    const day = String(dayBeforeYesterday.getDate()).padStart(2, "0");

    return `${year}${month}${day}`; // "YYYYMMDD" 형식
  };

  const getDayBeforeYesterdayYYYYMMDD = () => {
    const dayBeforeYesterday = new Date();
    dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2); // 이틀 전 날짜 설정

    // YYYYMMDD 형식으로 변환
    const year = dayBeforeYesterday.getFullYear();
    const month = String(dayBeforeYesterday.getMonth() + 1).padStart(2, "0");
    const day = String(dayBeforeYesterday.getDate()).padStart(2, "0");

    return `${year}${month}${day}`; // "YYYYMMDD" 형식
  };

  const getPrevYear = () => {
    const prevYear = new Date();
    prevYear.setDate(prevYear.getFullYear() - 1); // 어제 날짜로 설정
    return prevYear.toISOString().split('T')[0]; // "YYYY-MM-DD" 형식
  };

  const headerStyle = {
    backgroundColor: "#f4f4f4",
    fontWeight: "bold",
    border: "1px solid #ddd",
    padding: "8px",
  };

  const cellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
  };

  const highlightStyle = (isHighlighted: any) => ({
    color: isHighlighted ? "red" : "inherit",
    fontWeight: isHighlighted ? "bold" : "normal",
  });

  const roundTo = (number: number, decimals: number) => {
    const factor = Math.pow(10, decimals);
    return Math.round(number * factor) / factor;
  };

  return (
    <div style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 10000,
      padding: 20,
      overflowY: 'auto'
    }}>
      <p style={{
        fontSize: 20,
      }}>
        Day: {getYesterday()}
      </p>

      <p style={{
        fontSize: 20,
      }}>
        update : {updateTime}
      </p>

      {
        northstarmetric &&
        <div>
          <div style={{ overflowX: "auto", overflowY: "auto" }}>
            <br/>
            <h2>연도</h2>
            <table style={{ borderCollapse: "collapse", width: "50%", textAlign: "center" }}>
              <thead>
              <tr>
                <th scope="col" className="min-w-50px" style={headerStyle}>항목</th>
                <th scope="col" className="min-w-100px" style={headerStyle}>{formattedLastYear}</th>
                <th scope="col" className="min-w-100px" style={headerStyle}>{formattedCurrentYear}</th>
                <th scope="col" className="min-w-100px" style={headerStyle}>증감</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "left", ...highlightStyle(true), border: "1px solid #ddd", padding: "8px" }}>총 예약시간</td>
                  <td style={cellStyle}>{Number(northstarmetric[lastYear].t1).toLocaleString('en')}</td>
                  <td style={cellStyle}>{Number(northstarmetric[currentYear].t1).toLocaleString('en')}</td>
                  <td style={cellStyle}>{roundTo(((northstarmetric[currentYear].t1 - northstarmetric[lastYear].t1) /northstarmetric[lastYear].t1 * 100), 2)}%</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>활성 게스트 수</td>
                  <td style={cellStyle}>{Number(northstarmetric[lastYear].t2).toLocaleString('en')}</td>
                  <td style={cellStyle}>{Number(northstarmetric[currentYear].t2).toLocaleString('en')}</td>
                  <td style={cellStyle}>{roundTo(((northstarmetric[currentYear].t2 - northstarmetric[lastYear].t2) /northstarmetric[lastYear].t2 * 100), 2)}%</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>게스트당 평균 예약 건수</td>
                  <td style={cellStyle}>{northstarmetric[lastYear].t3}</td>
                  <td style={cellStyle}>{northstarmetric[currentYear].t3}</td>
                  <td style={cellStyle}>{roundTo(((northstarmetric[currentYear].t3 - northstarmetric[lastYear].t3) /northstarmetric[lastYear].t3 * 100), 2)}%</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>예약당 평균 시간</td>
                  <td style={cellStyle}>{northstarmetric[lastYear].t4}</td>
                  <td style={cellStyle}>{northstarmetric[currentYear].t4}</td>
                  <td style={cellStyle}>{roundTo(((northstarmetric[currentYear].t4 - northstarmetric[lastYear].t4) /northstarmetric[lastYear].t4 * 100), 2)}%</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>시간당 평균 요금</td>
                  <td style={cellStyle}>{Number(northstarmetric[lastYear].t5).toLocaleString('en')}</td>
                  <td style={cellStyle}>{Number(northstarmetric[currentYear].t5).toLocaleString('en')}</td>
                  <td style={cellStyle}>{roundTo(((northstarmetric[currentYear].t5 - northstarmetric[lastYear].t5) /northstarmetric[lastYear].t5 * 100), 2)}%</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>매출</td>
                  <td style={cellStyle}>{Number(northstarmetric[lastYear].t6).toLocaleString('en')}</td>
                  <td style={cellStyle}>{Number(northstarmetric[currentYear].t6).toLocaleString('en')}</td>
                  <td style={cellStyle}>{roundTo(((northstarmetric[currentYear].t6 - northstarmetric[lastYear].t6) /northstarmetric[lastYear].t6 * 100), 2)}%</td>
                </tr>
              </tbody>
            </table>
          </div>

          <br/>
          <br/>
          <br/>
          <div>
            <h2>월간</h2>
            <table style={{ borderCollapse: "collapse", width: "50%", textAlign: "center" }}>
              <thead>
              <tr>
                <th scope="col" className="min-w-50px" style={headerStyle}>항목</th>
                <th scope="col" className="min-w-100px" style={headerStyle}>{formattedLastYearMonth}</th>
                <th scope="col" className="min-w-100px" style={headerStyle}>{formattedCurrentYearMonth}</th>
                <th scope="col" className="min-w-100px" style={headerStyle}>증감</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(true), border: "1px solid #ddd", padding: "8px" }}>총 예약시간</td>
                <td style={cellStyle}>{Number(northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t1).toLocaleString('en')}</td>
                <td style={cellStyle}>{Number(northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t1).toLocaleString('en')}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t1 - northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t1) /northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t1 * 100), 2)}%</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>활성 게스트 수</td>
                <td style={cellStyle}>{Number(northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t2).toLocaleString('en')}</td>
                <td style={cellStyle}>{Number(northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t2).toLocaleString('en')}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t2 - northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t2) /northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t2 * 100), 2)}%</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>게스트당 평균 예약 건수</td>
                <td style={cellStyle}>{northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t3}</td>
                <td style={cellStyle}>{northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t3}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t3 - northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t3) /northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t3 * 100), 2)}%</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>예약당 평균 시간</td>
                <td style={cellStyle}>{northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t4}</td>
                <td style={cellStyle}>{northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t4}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t4 - northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t4) /northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t4 * 100), 2)}%</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>시간당 평균 요금</td>
                <td style={cellStyle}>{Number(northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t5).toLocaleString('en')}</td>
                <td style={cellStyle}>{Number(northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t5).toLocaleString('en')}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t5 - northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t5) /northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t5 * 100), 2)}%</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>매출</td>
                <td style={cellStyle}>{Number(northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t6).toLocaleString('en')}</td>
                <td style={cellStyle}>{Number(northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t6).toLocaleString('en')}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[`${currentYear.toString()}${currentMonth.toString().padStart(2, "0")}`].t6 - northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t6) /northstarmetric[`${lastYear.toString()}${lastMonth.toString().padStart(2, "0")}`].t6 * 100), 2)}%</td>
              </tr>
              </tbody>
            </table>
          </div>

          <br/>
          <br/>
          <br/>
          <div>
            <h2>일간</h2>
            <table style={{ borderCollapse: "collapse", width: "50%", textAlign: "center" }}>
              <thead>
              <tr>
                <th scope="col" className="min-w-50px" style={headerStyle}>항목</th>
                <th scope="col" className="min-w-100px" style={headerStyle}>{getDayBeforeYesterday()}</th>
                <th scope="col" className="min-w-100px" style={headerStyle}>{getYesterday()}</th>
                <th scope="col" className="min-w-100px" style={headerStyle}>증감</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(true), border: "1px solid #ddd", padding: "8px" }}>총 예약시간</td>
                <td style={cellStyle}>{Number(northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t1).toLocaleString('en')}</td>
                <td style={cellStyle}>{Number(northstarmetric[getYesterdayYYYYMMDD()].t1).toLocaleString('en')}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[getYesterdayYYYYMMDD()].t1 - northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t1) /northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t1 * 100), 2)}%</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>활성 게스트 수</td>
                <td style={cellStyle}>{Number(northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t2).toLocaleString('en')}</td>
                <td style={cellStyle}>{Number(northstarmetric[getYesterdayYYYYMMDD()].t2).toLocaleString('en')}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[getYesterdayYYYYMMDD()].t2 - northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t2) /northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t2 * 100), 2)}%</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>게스트당 평균 예약 건수</td>
                <td style={cellStyle}>{northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t3}</td>
                <td style={cellStyle}>{northstarmetric[getYesterdayYYYYMMDD()].t3}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[getYesterdayYYYYMMDD()].t3 - northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t3) /northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t3 * 100), 2)}%</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>예약당 평균 시간</td>
                <td style={cellStyle}>{northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t4}</td>
                <td style={cellStyle}>{northstarmetric[getYesterdayYYYYMMDD()].t4}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[getYesterdayYYYYMMDD()].t4 - northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t4) /northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t4 * 100), 2)}%</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>시간당 평균 요금</td>
                <td style={cellStyle}>{Number(northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t5).toLocaleString('en')}</td>
                <td style={cellStyle}>{Number(northstarmetric[getYesterdayYYYYMMDD()].t5).toLocaleString('en')}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[getYesterdayYYYYMMDD()].t5 - northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t5) /northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t5 * 100), 2)}%</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", ...highlightStyle(false), border: "1px solid #ddd", padding: "8px" }}>매출</td>
                <td style={cellStyle}>{Number(northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t6).toLocaleString('en')}</td>
                <td style={cellStyle}>{Number(northstarmetric[getYesterdayYYYYMMDD()].t6).toLocaleString('en')}</td>
                <td style={cellStyle}>{roundTo(((northstarmetric[getYesterdayYYYYMMDD()].t6 - northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t6) /northstarmetric[getDayBeforeYesterdayYYYYMMDD()].t6 * 100), 2)}%</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  )
}
